import * as React from 'react'
import styled from 'styled-components'
import {
  Paper,
  Button,
  TextField,
  Snackbar,
  IconButton,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { login } from '../../actions/user'
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert from '@material-ui/lab/Alert'
import ReactGA from 'react-ga'

const PageLoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const StyledPaper = styled(Paper)`
  && {
    max-width: 40rem;
    width: 100%;
    max-height: 20rem;
    height: 100%;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      margin-bottom: 2rem;
    }
  }
`

interface PageLoginProps {
  onLoginSuccess: () => void
}

const PageLogin = ({ onLoginSuccess }: PageLoginProps) => {
  const [loginState, setLoginState] = useState({
    username: '',
    password: '',
  })

  const [errorLogin, setErrorLogin] = useState('')
  const [loading, setLoading] = useState(false)

  const onChange = (key: string, value: string) => {
    setLoginState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const onLogin = async (event) => {
    event.preventDefault()
    const { username, password } = loginState
    setLoading(true)
    try {
      const JWT = await login(username, password)
      document.cookie = `${window.env.cookie}=${JWT};domain=${window.env.domain};path=/`
      ReactGA.event({
        category: 'Action',
        action: `Login Clicked`,
        label: username,
      })
      onLoginSuccess()
    } catch (e) {
      setErrorLogin(e.error)
    }
    setLoading(false)
  }

  useEffect(() => {
    ReactGA.pageview('Login')
  }, [])

  return (
    <PageLoginWrapper data-testid="PageLogin-test-id">
      <StyledPaper elevation={3} component="form" onSubmit={onLogin}>
        <TextField
          placeholder="Username/Email"
          fullWidth
          label="Корисничко име / емаил адреса"
          onKeyUp={(event) => {
            // @ts-ignore
            onChange('username', event.target.value)
          }}
        />
        <TextField
          placeholder="Password"
          type="password"
          fullWidth
          label="Лозинка"
          onKeyUp={(event) => {
            // @ts-ignore
            onChange('password', event.target.value)
          }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!(loginState.username && loginState.password) || loading}
          onClick={onLogin}
        >
          Најави се
        </Button>
      </StyledPaper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!errorLogin}
        autoHideDuration={3000}
        onClose={() => setErrorLogin('')}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {errorLogin}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setErrorLogin('')}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </MuiAlert>
      </Snackbar>
    </PageLoginWrapper>
  )
}

export default PageLogin
