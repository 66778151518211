import { Topic } from './typings/types'

export const topics: Topic[] = [
  {
    id: 1,
    title: 'Топ теми на денот',
    link: 'https://time.mk/rss/all',
    color: '#C56C6C',
  },
  {
    id: 2,
    title: 'Вести',
    link: 'https://time.mk/rss/vesti',
    color: '#C56C6C',
  },
  {
    id: 3,
    title: 'Македонија',
    link: 'https://time.mk/rss/makedonija',
    color: '#C56C6C',
  },
  {
    id: 4,
    title: 'Економија',
    link: 'https://time.mk/rss/ekonomija',
    color: '#C56C6C',
  },
  {
    id: 5,
    title: 'Балкан',
    link: 'https://time.mk/rss/balkan',
    color: '#C56C6C',
  },
  {
    id: 6,
    title: 'Свет',
    link: 'https://time.mk/rss/svet',
    color: '#C56C6C',
  },
  {
    id: 7,
    title: 'Скопје',
    link: 'https://time.mk/rss/skopje',
    color: '#C56C6C',
  },
  {
    id: 8,
    title: 'Хроника',
    link: 'https://time.mk/rss/hronika',
    color: '#C56C6C',
  },
  {
    id: 9,
    title: 'Република',
    link: 'https://time.mk/rss/republika',
    color: '#C56C6C',
  },
  {
    id: 10,
    title: 'Култура',
    link: 'https://time.mk/rss/kultura',
    color: '#C56C6C',
  },
  {
    id: 11,
    title: 'Технологија',
    link: 'https://time.mk/rss/tehnologija',
    color: '#C56C6C',
  },
  {
    id: 12,
    title: 'Сцена',
    link: 'https://time.mk/rss/scena',
    color: '#C56C6C',
  },
  {
    id: 13,
    title: 'Спорт',
    link: 'https://time.mk/rss/sport',
    color: '#4f5e66',
  },
  {
    id: 14,
    title: 'Фудбал',
    link: 'https://time.mk/rss/fudbal',
    color: '#4f5e66',
  },
  {
    id: 15,
    title: 'Кошарка',
    link: 'https://time.mk/rss/kosarka',
    color: '#4f5e66',
  },
  {
    id: 16,
    title: 'Ракомет',
    link: 'https://time.mk/rss/rakomet',
    color: '#4f5e66',
  },
  {
    id: 17,
    title: 'Тенис',
    link: 'https://time.mk/rss/tenis',
    color: '#4f5e66',
  },
  {
    id: 18,
    title: 'Автомото',
    link: 'https://time.mk/rss/avtomoto',
    color: '#4f5e66',
  },
  {
    id: 19,
    title: 'Останато',
    link: 'https://time.mk/rss/sportdrugo',
    color: '#4f5e66',
  },
  {
    id: 20,
    title: 'Магазин',
    link: 'https://time.mk/rss/magazin',
    color: '#ffffff',
  },
  {
    id: 21,
    title: 'Забава',
    link: 'https://time.mk/rss/fun',
    color: '#ffffff',
  },
  {
    id: 22,
    title: 'Автомобили',
    link: 'https://time.mk/rss/avto',
    color: '#ffffff',
  },
  {
    id: 23,
    title: 'Живот',
    link: 'https://time.mk/rss/life',
    color: '#ffffff',
  },
  {
    id: 24,
    title: 'Патувања',
    link: 'https://time.mk/rss/patuvanja',
    color: '#ffffff',
  },
  {
    id: 25,
    title: 'Здравје',
    link: 'https://time.mk/rss/zdravje',
    color: '#ffffff',
  },
  {
    id: 26,
    title: 'Храна',
    link: 'https://time.mk/rss/hrana',
    color: '#ffffff',
  },
  {
    id: 27,
    title: 'Стил',
    link: 'https://time.mk/rss/style',
    color: '#ffffff',
  },
  {
    id: 28,
    title: 'Интима',
    link: 'https://time.mk/rss/sex',
    color: '#ffffff',
  },
]
