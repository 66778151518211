interface Route {
  title: string
  path: string
}

const routes: Route[] = [
  {
    title: 'Почетна',
    path: '/',
  },
  {
    title: 'Mои Вести',
    path: '/my-news',
  },
  {
    title: 'Топ Твитови',
    path: '/tweets',
  },
  {
    title: 'За Нас',
    path: '/about',
  },
]

export default routes
