import * as React from 'react'
import styled from 'styled-components'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { topics } from '../../constants'
import utils from '../../utils'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { useEffect, useState } from 'react'
import NewsList from '../NewsList'
import { Topic } from '../../typings/types'
import MenuIcon from '@material-ui/icons/Menu'
import { Dialog } from '@material-ui/core'
import ReactGA from 'react-ga'

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  height: inherit;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 32rem) {
    grid-template-columns: 100%;
  }
`

const Menu = styled.div`
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  border-right: 1px solid #ccc;

  @media (max-width: 32rem) {
    display: none;
  }
`

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  width: 97%;
  cursor: pointer;
  text-decoration: none;
  color: #4a4a4a;
  background: ${(props: { selected?: boolean }) =>
    props.selected ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  transition: 0.2s;
  padding: 0.75rem 0 0.75rem 0.5rem;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    transition: 0.2s;
    box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.1);
  }
`

const StyledDashboardIcon = styled(DashboardIcon)`
  && {
    fill: ${(props: { fillColor: string }) => props.fillColor};
    margin-right: 0.5rem;
  }
`

const MobileMenu = styled.div`
  display: none;

  @media (max-width: 32rem) {
    background: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 1rem;

    > span {
      margin-left: 1rem;
    }
  }
`

type Props = RouteComponentProps<{ id: string }>

const Dashboard = ({
  match: {
    params: { id },
  },
}: Props) => {
  const [selectedTopic, setSelectedTopic] = useState<Topic>(
    topics.find((t) => utils.nameToUrl(t.title) === id) || topics[0]
  )
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  useEffect(() => {
    ReactGA.pageview('Dashboard')
  }, [])

  const onTopicSelect = (newTopic: Topic) => {
    setSelectedTopic(newTopic)
    setShowMobileMenu(false)
    ReactGA.event({
      category: 'Action',
      action: 'Select Topic',
      label: newTopic.title,
    })
  }

  return (
    <div data-testid="Dashboard-test-id">
      <Container>
        <Menu>
          {topics.map((topic: Topic) => (
            <MenuItem
              key={topic.title}
              to={utils.nameToUrl(topic.title)}
              onClick={() => onTopicSelect(topic)}
              selected={selectedTopic.id === topic.id}
            >
              <StyledDashboardIcon fillColor={topic.color} /> {topic.title}
            </MenuItem>
          ))}
        </Menu>
        <MobileMenu onClick={() => setShowMobileMenu(true)}>
          <MenuIcon /> <span>{selectedTopic.title}</span>
        </MobileMenu>
        <NewsList topic={selectedTopic} />
      </Container>
      <Dialog
        open={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
        fullWidth={true}
        maxWidth="xl"
      >
        {topics.map((topic: Topic) => (
          <MenuItem
            key={topic.title}
            to={utils.nameToUrl(topic.title)}
            onClick={() => onTopicSelect(topic)}
            selected={selectedTopic.id === topic.id}
          >
            <StyledDashboardIcon fillColor={topic.color} /> {topic.title}
          </MenuItem>
        ))}
      </Dialog>
    </div>
  )
}

export default withRouter(Dashboard)
