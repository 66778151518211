import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import { NewsItemProps } from '../../typings/types'
import moment from 'moment'
import Skeleton from '@material-ui/lab/Skeleton'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import SocialShare from '../SocialShare'

const StyledCardActions = styled(CardActions)`
  position: relative;
`

const StyledA = styled.a`
  text-decoration: none;
  color: #4f5e66;
  position: absolute;
  right: 1rem;

  &:hover {
    text-decoration: underline;
  }
`

const NewsImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 25rem;
  object-fit: contain;
  background: #f2f2f2;
  cursor: pointer;

  @media (max-width: 32rem) {
    max-height: 15rem;
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: '1rem',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

const NewsItem = ({
  category,
  content,
  guid,
  img,
  link,
  pubDate,
  source,
  sources,
  title,
  totalSourcesCount,
}: NewsItemProps) => {
  const classes = useStyles()

  const onNewsClick = () => {
    if (link) {
      window.open(link, '_blank')
      ReactGA.event({
        category: 'Action',
        action: `News View`,
        label: title,
      })
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        onClick={onNewsClick}
        title={
          title || (
            <React.Fragment>
              <Skeleton height={35} variant="text" />
              <Skeleton height={35} width="80%" variant="text" />
            </React.Fragment>
          )
        }
        style={{ cursor: 'pointer' }}
        subheader={
          pubDate ? (
            moment(pubDate).format('DD MMM YYYY')
          ) : (
            <Skeleton width={200} />
          )
        }
      />
      {img ? (
        <NewsImage
          src={img}
          onError={(element) =>
            (element.currentTarget.src = '/photo-placeholder.jpg')
          }
          title={title}
          onClick={() => window.open(link, '_blank')}
        />
      ) : (
        <Skeleton variant="rect" width="100%" height={400} />
      )}

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {content || (
            <React.Fragment>
              <Skeleton height={24} variant="text" />
              <Skeleton height={24} width="70%" variant="text" />
              <Skeleton height={24} width="30%" variant="text" />
            </React.Fragment>
          )}
        </Typography>
      </CardContent>
      <StyledCardActions disableSpacing>
        <SocialShare link={link} title={title} />
        {totalSourcesCount && (
          <StyledA
            href={totalSourcesCount.link}
            target="_blank"
            rel="noreferrer noopener nofollow"
            onClick={() => {
              ReactGA.event({
                category: 'Action',
                action: `All News View`,
                label: title,
              })
            }}
          >
            {totalSourcesCount.text}
          </StyledA>
        )}
      </StyledCardActions>
    </Card>
  )
}

export default NewsItem
