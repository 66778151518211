import { NewsItemProps, Topic, User } from '../typings/types'
import axios from 'axios'
import utils from '../utils'
import JWT from 'jwt-decode'

export const getNews = async (topic: Topic) => {
  try {
    const { data } = await axios.post(`${window.env.API_HOST}/v2/news`, {
      link: topic.link,
    })
    return data.data as NewsItemProps[]
  } catch (e) {
    throw e
  }
}

export const getMyNews = async () => {
  const jwt = utils.readCookie(window.env.cookie)
  const user: User | null = jwt ? JWT(jwt as string) : null
  try {
    const { data } = await axios.get(
      `${window.env.API_HOST}/v2/mynews/${user?.id_user}`,
      {
        headers: {
          authorization: jwt,
        },
      }
    )
    return data.data as NewsItemProps[]
  } catch (e) {
    throw e
  }
}
