import * as React from 'react'
import ShareIcon from '@material-ui/icons/Share'
import Popover from '@material-ui/core/Popover'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import IconButton from '@material-ui/core/IconButton'
import ReactGA from 'react-ga'
import styled from 'styled-components'

const ShareButtonsWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  padding: 0.5rem;
`

const StyledIconButton = styled(IconButton)`
  @media (max-width: 32rem) {
    && {
      padding: 0.5rem;
    }
  }
`

interface SocialShareProps {
  link: string
  title: string
}

const SocialShare = ({ link, title }: SocialShareProps) => {
  const [
    displayShareMenu,
    setDisplaySetMenu,
  ] = React.useState<SVGSVGElement | null>(null)

  const handleShareClick = (event: React.MouseEvent<SVGSVGElement>) =>
    setDisplaySetMenu(event.currentTarget)
  const popuoverId = !!displayShareMenu ? 'simple-popover' : undefined

  const onShareClick = (shareNetwork: string) => {
    ReactGA.event({
      category: 'Action',
      action: `News Share - ${shareNetwork}`,
      label: title,
    })
  }

  return (
    <StyledIconButton title="Share" aria-label="share">
      <ShareIcon aria-describedby={popuoverId} onClick={handleShareClick} />
      <Popover
        id={popuoverId}
        open={!!displayShareMenu}
        anchorEl={displayShareMenu}
        onClose={() => setDisplaySetMenu(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ShareButtonsWrapper>
          <FacebookShareButton
            url={link}
            onClick={() => onShareClick('Facebook')}
          >
            <img
              src={`${process.env.PUBLIC_URL}/fb.svg`}
              alt={`${title} Facebook Share`}
              width={24}
              height={24}
            />
          </FacebookShareButton>
          <TwitterShareButton
            url={link}
            onClick={() => onShareClick('Twitter')}
          >
            <img
              src={`${process.env.PUBLIC_URL}/tw.svg`}
              alt={`${title} Twitter Share`}
              width={24}
              height={24}
            />
          </TwitterShareButton>
          <LinkedinShareButton
            url={link}
            onClick={() => onShareClick('LinkedIn')}
          >
            <img
              src={`${process.env.PUBLIC_URL}/ln.svg`}
              alt={`${title} LinkedIn Share`}
              width={24}
              height={24}
            />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={link}
            onClick={() => onShareClick('WhatsApp')}
          >
            <img
              src={`${process.env.PUBLIC_URL}/wp.svg`}
              alt={`${title} WhatsApp Share`}
              width={24}
              height={24}
            />
          </WhatsappShareButton>
        </ShareButtonsWrapper>
      </Popover>
    </StyledIconButton>
  )
}

export default SocialShare
