import * as React from 'react'
import { QueryObserverResult, useQuery } from 'react-query'
import { NewsItemProps } from '../../typings/types'
import { getMyNews } from '../../actions/news'
import NewsItem from '../NewsItem'
import styled from 'styled-components'

const NewsListWrapper = styled.div`
  padding: 0 1rem 0 1rem;
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  max-width: 50rem;
  margin: 0 auto;
`

const dummyNewsData: NewsItemProps[] = Array.from(Array(5), (_) => ({
  title: '',
  category: '',
  content: '',
  guid: '',
  img: '',
  link: '',
  pubDate: '',
  source: '',
  sources: {
    link: '',
    title: '',
  },
  totalSourcesCount: {
    link: '',
    text: '',
  },
}))

const MyNewsList = () => {
  const { data, isLoading }: QueryObserverResult<NewsItemProps[]> = useQuery(
    'getMyNews',
    getMyNews
  )

  return (
    <NewsListWrapper data-testid="NewsList-test-id">
      {isLoading &&
        dummyNewsData.map((item: NewsItemProps) => (
          <NewsItem {...item} key={item.title} />
        ))}
      {!isLoading &&
        data?.map((item: NewsItemProps) => (
          <NewsItem {...item} key={item.title} />
        ))}
    </NewsListWrapper>
  )
}
export default MyNewsList
