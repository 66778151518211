import axios from 'axios'

export const login = async (username, password) => {
  try {
    const { data } = await axios.post(`${window.env.API_HOST}/login`, {
      username,
      password,
    })
    return data.loggedInUser[0]
  } catch (e) {
    throw e.response.data
  }
}
