import * as React from 'react'
import PageLogin from '../PageLogin'
import { useEffect, useState } from 'react'
import MyNewsList from '../MyNews/MyNewsList'
import utils from '../../utils'
import ReactGA from 'react-ga'

const MyNewsDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    !!utils.readCookie(window.env.cookie)
  )
  const onLoginSuccess = () => {
    setIsLoggedIn(true)
  }

  useEffect(() => {
    ReactGA.pageview('MyNews Dashboard')
  }, [])

  return !isLoggedIn ? (
    <PageLogin onLoginSuccess={onLoginSuccess} />
  ) : (
    <MyNewsList />
  )
}

export default MyNewsDashboard
