import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import routes from '../../routes'

const TopbarWrapper = styled.div`
  background: #607d8b;
  height: 3.5rem;
  width: 100%;
  color: white;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  font-weight: 600;

  > img {
    cursor: pointer;
    width: 1.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  > div {
    cursor: pointer;

    @media (max-width: 32rem) {
      display: none;
    }
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  height: inherit;
  max-width: 67.5rem;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 32rem) {
    grid-template-columns: 10% 80% 15%;
  }
`

const MenuWrapper = styled.div`
  height: inherit;
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: inherit;
`

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  color: white;
  transition: 0.2s;
  border-bottom: 0.25rem solid
    ${(props: { selected?: boolean }) =>
      props.selected ? 'white' : 'transparent'};

  &:hover {
    transition: 0.2s;
    border-bottom: 0.25rem solid white;
  }
`

const AccountWrapper = styled.div`
  display: inline-table;
`

type Props = RouteComponentProps<{}>

const Topbar = ({ history }: Props) => (
  <TopbarWrapper data-testid="Topbar-test-id">
    <Container>
      <LogoWrapper onClick={() => history.push('/')}>
        <img src="/icon_new.png" alt="MKVesti Logo" />
        <div>МК Вести</div>
      </LogoWrapper>
      <MenuWrapper>
        <Menu>
          {routes.map((route) => (
            <MenuItem
              key={route.path}
              to={route.path}
              selected={route.path === history.location.pathname}
            >
              {route.title}
            </MenuItem>
          ))}
        </Menu>
      </MenuWrapper>
      <AccountWrapper />
    </Container>
  </TopbarWrapper>
)

export default withRouter(Topbar)
