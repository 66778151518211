import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Dashboard from '../Dashboard'
import Topbar from '../Topbar'
import MyNewsDashboard from '../MyNewsDashboard'
import PageAbout from '../PageAbout'
import PageTweets from '../PageTweets'
import PagePrivacy from '../PagePrivacy'
import { QueryClient, QueryClientProvider } from 'react-query'
import styled from 'styled-components'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

const App = () => (
  <Switch>
    <Route exact path="/privacy-policy" component={PagePrivacy} />
    <Route exact path="/my-news" component={MyNewsDashboard} />
    <Route exact path="/about" component={PageAbout} />
    <Route exact path="/tweets" component={PageTweets} />
    <Route exact path="/:id?" component={Dashboard} />
  </Switch>
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4f5e66',
      main: '#4f5e66',
      dark: '#4f5e66',
    },
    secondary: {
      main: '#CCC',
    },
  },
})

const Content = styled.div`
  height: calc(100vh - 3.5rem);
  margin-top: 1rem;
`

const MainApp = () => (
  <MuiThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Topbar />
        <Content>
          <App />
        </Content>
      </BrowserRouter>
    </QueryClientProvider>
  </MuiThemeProvider>
)

export default MainApp
