import * as React from 'react'
import { NewsItemProps, Topic } from '../../typings/types'
import { QueryObserverResult, useQuery } from 'react-query'
import { getNews } from '../../actions/news'
import { useEffect, useState } from 'react'
import NewsItem from '../NewsItem'
import styled from 'styled-components'

const NewsListWrapper = styled.div`
  padding: 0 1rem 0 1rem;
  height: calc(100vh - 4rem);
  overflow-y: scroll;
`

interface NewsListProps {
  topic: Topic
}

const dummyNewsData: NewsItemProps[] = Array.from(Array(5), (_) => ({
  title: '',
  category: '',
  content: '',
  guid: '',
  img: '',
  link: '',
  pubDate: '',
  source: '',
  sources: {
    link: '',
    title: '',
  },
  totalSourcesCount: {
    link: '',
    text: '',
  },
}))

const NewsList = ({ topic }: NewsListProps) => {
  const [viewingTopic, setViewingTopic] = useState<Topic>(topic)

  const { data, isLoading }: QueryObserverResult<NewsItemProps[]> = useQuery(
    ['getNews', viewingTopic],
    () => getNews(viewingTopic)
  )

  useEffect(() => {
    setViewingTopic(topic)
  }, [topic])

  return (
    <NewsListWrapper data-testid="NewsList-test-id">
      {isLoading &&
        dummyNewsData.map((item: NewsItemProps) => (
          <NewsItem {...item} key={item.title} />
        ))}
      {!isLoading &&
        data?.map((item: NewsItemProps) => (
          <NewsItem {...item} key={item.title} />
        ))}
    </NewsListWrapper>
  )
}

export default NewsList
