import * as React from 'react'
import { useState } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import styled from 'styled-components'
import { QueryObserverResult, useQuery } from 'react-query'
import { Tweet } from '../../typings/types'
import { getTweets } from '../../actions/tweets'
import TweetItem from '../TweetItem'

const TweetsWrapper = styled.div`
  padding: 0 1rem 0 1rem;
  height: calc(100vh - 6rem);
  overflow-y: scroll;
  max-width: 50rem;
  margin: 0 auto;
`

const PagesWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 1rem;
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 32rem) {
    grid-column-gap: 0.5rem;
  }
`

const Page = styled.div`
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background: ${(props: { selected: boolean }) =>
    props.selected ? `#ccc` : `white`};
  cursor: pointer;
  transition: 0.2s;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    transition: 0.2s;
    box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.1);
  }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    inline: {
      display: 'inline',
    },
    listItem: {
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
      cursor: 'pointer',
    },
  })
)

const PAGES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const dummyTweetData: Tweet[] = Array.from(Array(5), (_) => ({
  timestamp: '',
  favourite: '',
  retweet: '',
  reply: '',
  favourites: '',
  retweets: '',
  tweetId: '',
  nick: '',
  content: '',
  profilePicture: '',
  name: '',
  profileUrl: '',
}))

const PageTweets = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const { data, isLoading }: QueryObserverResult<Tweet[]> = useQuery(
    ['getTweets', selectedPage],
    () => getTweets('2h', selectedPage.toString())
  )

  const classes = useStyles()

  const onSelectPage = (page: number) => {
    if (page !== selectedPage) {
      setSelectedPage(page)
    }
  }

  return (
    <React.Fragment>
      <PagesWrapper>
        {PAGES.map((page) => (
          <Page
            selected={page === selectedPage}
            onClick={() => onSelectPage(page)}
            key={page}
          >
            {page}
          </Page>
        ))}
      </PagesWrapper>
      <TweetsWrapper>
        <List className={classes.root}>
          {isLoading &&
            dummyTweetData.map((item: Tweet, index: number) => (
              <TweetItem {...item} key={index} />
            ))}
          {data &&
            data.map((item: Tweet) => (
              <TweetItem {...item} key={item.tweetId} />
            ))}
        </List>
      </TweetsWrapper>
    </React.Fragment>
  )
}

export default PageTweets
