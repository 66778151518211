import * as React from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'

const AboutWrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

const Paragraph = styled.p`
  line-height: 1.5;
  margin-bottom: 1rem;
`

const SyledA = styled.a`
  text-decoration: none;
  color: #4f5e66;
  font-weight: 500;
`

ReactGA.pageview('About')

const PageAbout = () => (
  <AboutWrapper>
    <Paragraph>
      МК Вести е апликација која ги прикажува најновите вести од Македонија и
      светот. Сите на едно место.
    </Paragraph>
    <Paragraph>
      Сите вести се преземени од веб страната{' '}
      <SyledA
        href="https://www.time.mk"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.time.mk
      </SyledA>
    </Paragraph>
    <Paragraph>
      <a
        href="https://play.google.com/store/apps/details?id=com.slj.time.mk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${process.env.PUBLIC_URL}/play.png`}
          height={70}
          alt="MK Vesti Google Play"
        />
      </a>
      <a
        href="https://appgallery.huawei.com/#/app/C103238875"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${process.env.PUBLIC_URL}/app_gallery.png`}
          width={220}
          height={70}
          alt="MK Vesti Huawei App Hallery"
        />
      </a>
      <a
        href="https://www.facebook.com/mkvesti.com.1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${process.env.PUBLIC_URL}/facebook.png`}
          width={220}
          height={70}
          alt="MK Vesti Facebook"
        />
      </a>
    </Paragraph>
  </AboutWrapper>
)

export default PageAbout
