import axios from 'axios'
import { Tweet } from '../typings/types'

export const getTweets = async (time: string, page: string) => {
  try {
    const { data } = await axios.get(
      `${window.env.API_HOST}/v2/tweets/${time}/${page}`
    )
    return data.data as Tweet[]
  } catch (e) {
    throw e
  }
}
