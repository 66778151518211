import * as React from 'react'
import { Tweet } from '../../typings/types'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import styled from 'styled-components'
import SocialShare from '../SocialShare'
import CardActions from '@material-ui/core/CardActions'
import utils from '../../utils'
import ReplyIcon from '@material-ui/icons/Reply'
import FavoriteIcon from '@material-ui/icons/Favorite'
import RetweetIcon from '@material-ui/icons/Repeat'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Skeleton from '@material-ui/lab/Skeleton'
import ViewIcon from '@material-ui/icons/Visibility'

const StyledCardActions = styled(CardActions)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: grey;
`

const ListItemHolder = styled.div`
  background: white;
  margin-bottom: 1rem;
  position: relative;
`

const TweetInfo = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  text-align: right;
`

const StyledIconButton = styled(IconButton)`
  @media (max-width: 32rem) {
    && {
      padding: 0.5rem;
    }
  }
`

const StyledA = styled.a`
  text-decoration: none;
  color: #4f5e66;

  &:hover {
    text-decoration: underline;
  }
`

const Timestamp = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: grey;
  z-index: 1;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    inline: {
      display: 'inline',
    },
    listItem: {
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
      cursor: 'pointer',
    },
  })
)

const TWITTER_BASE_URL = 'https://twitter.com'

const TweetItem = ({
  tweetId,
  profileUrl,
  name,
  profilePicture,
  content,
  nick,
  retweets,
  favourites,
  reply,
  retweet,
  favourite,
  timestamp,
}: Tweet) => {
  const classes = useStyles()
  return (
    <ListItemHolder>
      <Timestamp>{timestamp}</Timestamp>
      <ListItem
        alignItems="flex-start"
        className={classes.listItem}
        key={content}
      >
        <ListItemAvatar className={classes.avatar}>
          <Avatar
            alt={name}
            src={profilePicture}
            onClick={() => window.open(profileUrl, '_blank')}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            (name && (
              <StyledA
                href={profileUrl}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                {name} | {nick}
              </StyledA>
            )) || <Skeleton width="70%" />
          }
          secondary={
            (content && (
              <span dangerouslySetInnerHTML={{ __html: content }} />
            )) || <Skeleton width="100%" />
          }
        />
      </ListItem>
      <StyledCardActions disableSpacing>
        <div>
          {(content && (
            <React.Fragment>
              <SocialShare
                link={`${TWITTER_BASE_URL}/${nick}/status/${tweetId}`}
                title={content}
              />
              <StyledIconButton
                title="Reply"
                onClick={() => utils.popupWindow(reply, 'Reply', 800, 600)}
              >
                <ReplyIcon />
              </StyledIconButton>
              <StyledIconButton
                title="Retweet"
                onClick={() => utils.popupWindow(retweet, 'Retweet', 800, 600)}
              >
                <RetweetIcon />
              </StyledIconButton>
              <StyledIconButton
                title="Like"
                onClick={() => utils.popupWindow(favourite, 'Like', 800, 600)}
              >
                <FavoriteIcon />
              </StyledIconButton>
              <StyledIconButton
                title="View"
                onClick={() =>
                  window.open(
                    `${TWITTER_BASE_URL}/${nick}/status/${tweetId}`,
                    '_blank'
                  )
                }
              >
                <ViewIcon />
              </StyledIconButton>
            </React.Fragment>
          )) || <Skeleton width={100} />}
        </div>
        <TweetInfo>
          <div>
            {(retweets && (
              <React.Fragment>
                <strong>{retweets}</strong> Retweets
              </React.Fragment>
            )) || <Skeleton width={50} />}
          </div>
          <div>
            {(favourites && (
              <React.Fragment>
                <strong>{favourites}</strong> Likes
              </React.Fragment>
            )) || <Skeleton width={50} />}
          </div>
        </TweetInfo>
      </StyledCardActions>
    </ListItemHolder>
  )
}

export default TweetItem
