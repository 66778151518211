const removeUnicode = (str: string) => {
  if (!str) return ''
  // eslint-disable-next-line
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  // eslint-disable-next-line
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  // eslint-disable-next-line
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  // eslint-disable-next-line
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  // eslint-disable-next-line
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  // eslint-disable-next-line
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  // eslint-disable-next-line
  str = str.replace(/đ/g, 'd')
  // eslint-disable-next-line
  str = str.replace(
    // eslint-disable-next-line
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,
    '-'
  )

  // eslint-disable-next-line
  str = str.replace(/-+-/g, '-')
  // eslint-disable-next-line
  str = str.replace(/^\-+|\-+$/g, '')

  return str
}

const nameToUrl = (name: string) => {
  const plainString = removeUnicode(name)
  return plainString.split(' ').join('-').toLowerCase()
}

const parseXml = (xmlStr: string) =>
  new window.DOMParser().parseFromString(xmlStr, 'text/xml')

const extractContent = (s: string) => {
  const span = document.createElement('span')
  span.innerHTML = s
  return span?.lastElementChild?.firstElementChild?.firstElementChild
    ?.lastElementChild?.firstElementChild?.childNodes[3].textContent
}

const extractOtherSources = (s: string) => {
  const span = document.createElement('span')
  span.innerHTML = s
  const links = span?.lastElementChild?.firstElementChild?.firstElementChild?.lastElementChild?.firstElementChild?.getElementsByTagName(
    'a'
  )
  const titles = span?.lastElementChild?.firstElementChild?.firstElementChild?.lastElementChild?.firstElementChild?.getElementsByTagName(
    'b'
  )
  return Array.prototype.slice.call(links).map((link, index) => ({
    title: Array.prototype.slice.call(titles)[index].textContent,
    link: link.href,
  }))
}

const getTotalCount = (s: string) => {
  const span = document.createElement('span')
  span.innerHTML = s
  const total = span?.lastElementChild?.firstElementChild?.firstElementChild?.lastElementChild?.firstElementChild?.getElementsByTagName(
    'strong'
  )
  return {
    link:
      // @ts-ignore
      total && total[0] && total[0].children[0] && total[0].children[0].href,
    text:
      total &&
      total[0] &&
      total[0].children[0] &&
      total[0].children[0].textContent,
  }
}

const readCookie = (name: string) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

const popupWindow = (url: string, title: string, w: number, h: number) => {
  const dualScreenLeft = window.screenLeft
  const dualScreenTop = window.screenTop

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    `scrollbars=no, width=${w}, height=${h}, top=${top}, left=${left}`
  )

  if (newWindow) {
    newWindow.focus()
  }
}

export default {
  nameToUrl,
  parseXml,
  extractContent,
  extractOtherSources,
  getTotalCount,
  readCookie,
  popupWindow,
}
